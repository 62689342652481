<template>
  <div>
    <CustomerForm
      :errors="violations"
      :loading="isLoading"
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
      :values="item"
      ref="updateForm"
      v-if="item"
    />
    <ProfileForm
      :errors="violations"
      :loading="isProfileLoading"
      :values="profile"
      :handle-reset="resetProfileForm"
      :handle-submit="onSendProfileForm"
      ref="updateProfileForm"
      v-if="profile"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import CustomerForm from '../../components/customer/Form.vue';
import ProfileForm from '../../components/profile/Form.vue';
import UpdateMixin from '../../mixins/UpdateMixin';
import ProfileMixin from "@/mixins/ProfileMixin";
import DocumentMixin from "@/mixins/DocumentMixin";

const servicePrefix = 'Customer';

export default {
  name: 'CustomerUpdate',
  servicePrefix,
  mixins: [UpdateMixin, ProfileMixin, DocumentMixin],
  components: {
    CustomerForm,
    ProfileForm
  },
  data() {
    // overwrite update mixin data to set profile
    return {
      profileCreate: false
    };
  },
  computed: {
    ...mapFields('backendUser', {
      deleteLoading: 'isLoading',
      isLoading: 'isLoading',
      error: 'error',
      updated: 'updated',
      violations: 'violations'
    }),
    ...mapGetters('backendUser', ['find']),
    profile() {
      if (this.profileCreate && (typeof this.item.profile === 'object' && this.item.profile !== null)) {
        // create object, keep it simple
        return this.item.profile;
      }
      if (typeof this.item.profile !== "undefined") {
        return this.resolveProfile(this.item.profile);
      }
      return null;
    }
  },

  methods: {
    ...mapActions('backendUser', {
      deleteItem: 'del',
      retrieve: 'load',
      update: 'update',
    }),
    reset() {
      this.$refs.updateForm.$v.$reset();
      this.$refs.updateProfileForm.$v.$reset();
    },
    onSendProfileForm() {
      const updateForm = this.$refs.updateProfileForm;
      updateForm.$v.$touch();

      if (!updateForm.$v.$invalid) {
        if (updateForm.uploadImage) {
          this.uploadDocument(updateForm.uploadImage);
          return;
        }
        if (this.profileCreate === false) {
          this.updateProfile(updateForm.$v.item.$model);
        } else {
          this.createProfile(updateForm.$v.item.$model).then(() => {
            this.item.profile = this.createdProfile['@id'];
            this.onSendForm();
            this.profileCreate = false;
          });
        }
      }
    },
    resetProfileForm() {
      this.$refs.updateProfileForm.$v.$reset();
      this.item.profile = {...this.retrieved.profile};
    }
  },
  watch: {
    item() {
      if (this.item.profile === null) {
        this.profileCreate = true;
        this.item.profile = {};
        this.item.profile.backendUser = this.item['@id'];
      }
    },
    errorProfile(message) {
      message && this.showError(message);
    },
    updatedProfile(val) {
      this.showMessage(`${val['@id']} updated.`);
    },
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.profile.image = this.uploadedDocument['@id'];
      this.updateProfile(this.$refs.updateProfileForm.$v.item.$model);
    }
  }
};
</script>
