<template>
  <v-form>
    <v-card max-width="800px"
            class="mt-4 mx-auto"
            :loading="loading"
            :disabled="loading"
    >
      <v-card-title v-text="$t('Account')"/>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="emailErrors"
                :label="$t('email')"
                @blur="$v.item.email.$touch()"
                @input="$v.item.email.$touch()"
                required
                v-model="item.email"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" v-if="loginEnabledForCustomer">
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :error-messages="plainPasswordErrors"
                :label="$t('password')"
                @blur="$v.item.plainPassword.$touch()"
                @input="$v.item.plainPassword.$touch()"
                v-model="item.plainPassword"
              />
            </v-col>
          </v-row>
          <v-row v-if="loginEnabledForCustomer && !hasDefaultGroupOnly">
            <v-col cols="12" md="6" sm="6">
              <v-combobox
                v-model="itemGroups"
                :error-messages="groupsErrors"
                :items="customerGroups"
                :label="$t('groups')"
                :no-data-text="$t('No results')"
                chips
                item-text="name"
                item-value="@id"
                multiple
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" v-if="loginEnabledForCustomer">
              <v-checkbox
                :error-messages="enabledErrors"
                :label="$t('enabled')"
                @blur="$v.item.enabled.$touch()"
                @input="$v.item.enabled.$touch()"
                v-model="item.enabled"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" v-if="false">
              <v-checkbox
                :error-messages="lockedErrors"
                :label="$t('locked')"
                @blur="$v.item.locked.$touch()"
                @input="$v.item.locked.$touch()"
                v-model="item.locked"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto" color="primary" @click="submitItem">{{ $t('Submit') }}</v-btn>
        <v-btn class="ml-sm-2 mainAccent lighten-3" dark @click="resetItem">{{ $t('Reset') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import TenantMixin from "@/mixins/TenantMixin";
import FrontendGroupMixin from "@/mixins/FrontendGroupMixin";

export default {
  name: 'CustomerForm',
  mixins: [validationMixin, TenantMixin, FrontendGroupMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {
      }
    },

    loading: {
      type: Boolean,
      required: true
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    },

    handleSubmit: {
      type: Function,
      required: false
    },

    handleReset: {
      type: Function,
      required: false
    },

  },
  mounted() {
    this.groupsGetSelectItems();
  },
  data() {
    return {
      showPassword: false,
      groups: null,
      email: null,
      profile: null,
      plainPassword: null,
      enabled: null,
      locked: null,
    };
  },
  computed: {
    ...mapFields('frontendGroup', {
      groupsSelectItems: 'selectItems',
      groupsById: 'byId'
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    hasDefaultGroupOnly() {
      if (typeof this.item.groups === "undefined") {
        return false;
      }
      let accountSettings = this.getAccountSettings();
      if (accountSettings.defaultCustomerGroup) {
        if (this.item.groups.length === 1 && this.item.groups[0] === accountSettings.defaultCustomerGroup) {
          return true;
        }
      }
      return false;
    },
    customerGroups() {
      if (this.groupsSelectItems === null) {
        return [];
      }
      return this.groupsSelectItems;
    },
    // region validation
    groupsErrors() {
      const errors = [];

      if (!this.$v.item.groups.$dirty) {
        return errors;
      }

      has(this.violations, 'groups') && errors.push(this.violations.groups);

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.item.email.$dirty) {
        return errors;
      }

      has(this.violations, 'email') && errors.push(this.violations.email);

      !this.$v.item.email.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    plainPasswordErrors() {
      const errors = [];

      if (!this.$v.item.plainPassword.$dirty) {
        return errors;
      }

      has(this.violations, 'plainPassword') && errors.push(this.violations.plainPassword);
      !this.$v.item.plainPassword.minLength && errors.push(this.$t('MinChar6'));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, 'enabled') && errors.push(this.violations.enabled);

      return errors;
    },
    lockedErrors() {
      const errors = [];

      if (!this.$v.item.locked.$dirty) {
        return errors;
      }

      has(this.violations, 'locked') && errors.push(this.violations.locked);

      return errors;
    },
    violations() {
      return this.errors || {};
    },
    // endregion
    itemGroups: {
      get: function () {
        return this.resolveFrontendGroups(this.item.groups);
      },
      set: function (newValue) {
        this.item.groups = [];
        newValue.forEach((item) => {
          this.item.groups.push(item['@id']);
        });
      }
    },
    loginEnabledForCustomer(){
      return this.getAccountSettings().customerLoginEnabled;
    }
  },
  methods: {
    ...mapActions({
      groupsGetSelectItems: 'frontendGroup/fetchSelectItems'
    }),
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    }
  },
  validations: {
    item: {
      groups: {},
      email: {
        required,
      },
      plainPassword: {
        minLength: minLength(6)
      },
      enabled: {},
      locked: {},
    }
  }
};
</script>
