import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingFrontendGroups: [],
    };
  },
  computed: {
    ...mapFields("frontendGroup", {
      frontendGroupIds: "allIds",
      frontendGroupIdsCached: "cacheAllIds",
    }),
    ...mapGetters("frontendGroup", {
      findFrontendGroup: "find",
    }),
  },
  methods: {
    ...mapActions("frontendGroup", {retrieveFrontendGroup: "load"}),
    resolveFrontendGroup(itemId) {
      if (!this.isFrontendGroupLoaded(itemId) && !this.loadingFrontendGroups.includes(itemId)) {
        this.loadingFrontendGroups.push(itemId);
        this.retrieveFrontendGroup(decodeURIComponent(itemId));
      }
      return this.findFrontendGroup(itemId);
    },
    resolveFrontendGroups(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveFrontendGroup(itemId);
      }).filter(e => e);
    },
    getFrontendGroupItem(frontendGroupIri, type) {
      if (frontendGroupIri !== null) {
        let frontendGroup = this.resolveFrontendGroup(frontendGroupIri);
        if (frontendGroup) {
          return frontendGroup[type];
        }
      }
      return '';
    },
    isFrontendGroupLoaded(itemId) {
      return this.frontendGroupIdsCached.includes(itemId);
    },
    isFrontendGroupSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isFrontendGroupLoaded(itemId));
    },
    resolveFrontendGroupAsync(itemId) {
      const poll = resolve => {
        if (this.isFrontendGroupLoaded((itemId))) {
          resolve(this.resolveFrontendGroup(itemId));
        } else {
          this.resolveFrontendGroup(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveFrontendGroupsAsync(itemIds) {
      const poll = resolve => {
        if (this.isFrontendGroupSetLoaded((itemIds))) {
          resolve(this.resolveFrontendGroups(itemIds));
        } else {
          this.resolveFrontendGroup(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
